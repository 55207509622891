
































import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { MobilePaymentReceiptDialogProperties } from "./MobilePaymentReceiptDialogView.vue";
import { HomeProperties } from '@/components/home/HomeView.vue';
import EventManager from "@/utilities/EventManager";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import AddressUtil from "@/utilities/AddressUtil";
import ResponseUtil from '@/utilities/ResponseUtil';
import CustomerPaymentService from "@/services/CustomerPaymentService";
import CompanyService from "@/services/CompanyService";

const MobilePaymentDetailView = () => import("./MobilePaymentDetailView.vue");
const MobilePaymentSummaryView = () => import("./MobilePaymentSummaryView.vue");
const MobilePaymentReceiptDialogView = () => import("./MobilePaymentReceiptDialogView.vue");

class MobilePaymentInfoProperties {
    currency: any = {};
    payment: any = {};
    details: any[] = [];
    dateTimeFormat: string = null;
    events = new EventManager();
}

export { MobilePaymentInfoProperties };

@Component({
	metaInfo: {
		link: [{ rel: "stylesheet", href: "/home-payment-info.css" }]
	},
    components: { 
        MobilePaymentDetailView, 
        MobilePaymentSummaryView, 
        MobilePaymentReceiptDialogView 
    },
    data: () => ({
        loading: false,
        saving: false
    })
})
export default class MobilePaymentInfoView extends Vue {
    private properties = new MobilePaymentInfoProperties();
    @Prop() private home: HomeProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private paymentReceiptDialog = new MobilePaymentReceiptDialogProperties();
    private customerPaymentService = new CustomerPaymentService();
    private companyService = new CompanyService();

    public get isCheckout() {
        return this.properties.payment.type === 'Checkout';
    }

    public created() {
        this.load();
        this.properties.events.subscribe('cancel', this.cancelDialog);
        this.home.events.subscribe('alert-dialog-clicked', this.alertDialogClicked);
    }

    public destroyed() {
        this.properties.events.remove('cancel', this.cancelDialog);
        this.home.events.remove('alert-dialog-clicked', this.alertDialogClicked);
    }

    public async loadCurrency() {
        const r = await this.companyService.currency();
        this.properties.currency = r.data;
    }

    public async loadPayment() {
        var uniqueNo = this.$route.params.uniqueNo;
        const r = await this.customerPaymentService.get({
            uniqueNo,
            loadShipping: true,
        });
        if (r.data.customerPayments.length === 0) {
            throw new Error(this.$t("message.record-not-found").toString());
        }

        const payment = r.data.customerPayments[0];

        if (payment.shipping) {
            payment.shipping.address = AddressUtil.build(payment.shipping);
        }

        this.properties.payment = payment;
        this.properties.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);
        await this.properties.events.fire("load-details");
    }

    public async load() {
        this.$data.loading = true;

        try {
            await this.loadCurrency();
            await this.loadPayment();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.tag = { command: "load-error", redirect: "/m/payment" };
                
                if (this.$route.path.startsWith("/m/processing")) {
                    this.alertDialog.tag.redirect = "/m/processing";
                }

                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.loading = false;
        }
    }

    public async cancel() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            const payment = this.properties.payment;
            await this.customerPaymentService.cancel(payment);
            this.load();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public cancelDialog() {
        const t = this.$t("title.cancel-payment");
        const m = this.$t("message.cancel-payment");
        AlertDialogProperties.standard(this.alertDialog, t, m);
        this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
        this.alertDialog.primaryButton = this.$t("text.yes");
        this.alertDialog.tag = { command: "cancel-payment" };
        this.alertDialog.visible = true;
    }

    public alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        if (tag) {
            if (tag.command === 'cancel-payment' && button === this.$t('text.yes')) {
                this.cancel();
            }
        } 
    }
}
