



























import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { MobilePaymentInfoProperties } from "./MobilePaymentInfoView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import CustomerPaymentAttachmentService from "@/services/CustomerPaymentAttachmentService";

class MobilePaymentReceiptDialogProperties {
    visible: boolean = false;
}

export { MobilePaymentReceiptDialogProperties };

@Component({
    data: () => ({
        pageNo: null,
        receipt: null,
        receipts: [],
    }),
})
export default class MobilePaymentReceiptDialogView extends Vue {
    @Prop({ default: new MobilePaymentReceiptDialogProperties() })
    private properties: MobilePaymentReceiptDialogProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    @Prop() private paymentInfo: MobilePaymentInfoProperties;

    public get payment() {
        return this.paymentInfo.payment;
    }

    public receiptUrl(receipt: any) {
        const service = new CustomerPaymentAttachmentService();
        return service.previewUrl({ id: receipt.id });
    }

    public created() {
        this.load();
    }

    public async load() {
        try {
            const service = new CustomerPaymentAttachmentService();
            const r = await service.get({
                customerPaymentId: this.payment.id,
            });
            this.$data.receipts = r.data.sort((lhs, rhs) => lhs.id - rhs.id);
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public async receiptChanged() {
        this.$data.receipt = this.$data.receipts[this.$data.pageNo];
    }

    public close() {
        this.properties.visible = false;
    }
}
